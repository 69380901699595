@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.cdnfonts.com/css/jua");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	body {
		@apply bg-bodyBg font-poppins text-dark;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		@apply font-jua;
	}
	img,
	svg {
		@apply pointer-events-none;
	}
	.btn {
		@apply text-white bg-blue-default hover:bg-blue-hover active:bg-blue-pressed text-base py-2 px-4 md:text-lg md:py-[14px] md:px-8 font-semibold rounded-full duration-300 inline-flex items-center justify-center shadow-shadow;
	}
	.btn[disabled] {
		@apply bg-secondaryBg text-white;
	}
	.back-btn {
		@apply text-white bg-secondaryBg hover:bg-blue-hover active:bg-blue-pressed text-base py-2 px-4 md:text-lg md:py-[14px] md:px-8 font-semibold rounded-full duration-300 inline-flex items-center justify-center shadow-shadow;
	}
	.back-btn.disabled {
		@apply hover:bg-secondaryBg active:bg-secondaryBg;
	}
	.btn-outline {
		@apply text-blue-default border border-blue-default hover:bg-blue-default hover:text-white active:bg-blue-hover active:text-white text-lg py-[14px] font-semibold rounded-full px-8 duration-300 inline-flex items-center justify-center;
	}
	.step-3 .section-title {
		max-width: 837px;
	}
	.video-player {
		@apply rounded-lg lg:rounded-[40px];
	}
	.video-player div {
		@apply !h-auto;
	}
	.video-player iframe {
		@apply rounded-lg lg:rounded-[40px] lg:h-[438px] sm:h-[280px] h-[178px];
	}
	.share-with-friends .section-title {
		max-width: 873px;
	}
	.share-with-friends .section-title p {
		@apply mx-auto max-w-[600px] max-sm:max-w-[330px];
	}
	.presets-1 {
		display: none;
	}
	@media screen and (min-width: 500px) {
		.presets-1 {
			display: block;
		}
		.presets-2 {
			display: none;
		}
	}
	.before-clip-quote {
		@apply relative before:absolute before:w-8 before:h-10 before:bg-white before:right-0 before:top-full;
	}
	.before-clip-quote::before {
		clip-path: polygon(100% 0, 0 0, 100% 100%);
	}
	.step-1 .section-title h2 {
		@apply max-sm:max-w-[320px];
	}
	.nav-link {
		@apply block text-[#979797] [&.active]:text-dark [&.active]:before:absolute [&.active]:before:bg-blue-default px-[6px] relative before:top-[calc(100%+12px)] before:w-full before:left-0 before:h-[3px];
	}
	.btn-sm {
		@apply min-h-[26px] px-4 md:py-3 py-0 max-md:text-sm md:min-w-[176px];
	}
	.sidebar-dot-item {
		@apply flex flex-col justify-center items-center flex-grow last:after:opacity-0;
	}
	.sidebar-dot-item::after {
		content: "";
		@apply block w-[2px] h-1 flex-grow bg-grey3;
	}
	.sidebar-dot-item .item {
		@apply w-8 h-8 flex justify-center items-center border-2 border-grey3 rounded-full;
	}
	.sidebar-dot-item .item .dot {
		@apply w-[10px] h-[10px] bg-grey3 rounded-full;
	}
	.sidebar-dot-item.active::after {
		@apply bg-blue-default;
	}
	.sidebar-dot-item.active .item {
		@apply border-blue-default;
	}
	.sidebar-dot-item.active .item .dot {
		@apply bg-blue-default;
	}
	.download-btn {
		@apply text-[16px]  md:text-[17px] leading-[18px] text-white py-3 px-1 flex justify-center bg-[#FF7455] rounded-full items-center gap-2 duration-200 min-h-[50px];
	}
	.download-btn[disabled] {
		@apply bg-[#AEAEB2];
	}
	.download-btn.success {
		@apply bg-[#0BD58B];
	}
	.form-select {
		appearance: none;
		background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")
			no-repeat right 1.25rem center / 1rem 1rem;
	}
	.or {
		@apply text-[#979797] text-sm flex items-center gap-6 my-4 md:my-8;
	}
	.or::before,
	.or::after {
		content: "";
		@apply flex-grow bg-grey3 h-[1px];
	}
	.face-select-icon {
		@apply w-[26px] h-[26px] rounded-full border border-blue-default bg-white absolute right-3 top-3 flex justify-center items-center text-blue-default;
	}
	.face-select-icon svg {
		@apply w-4 h-4;
	}
	.selected-icon {
		@apply w-7 h-7 rounded-full bg-white absolute top-2 right-2 flex justify-center items-center;
	}
	.selected-icon svg {
		@apply w-5;
	}
	.avatar-container {
		@apply py-[53px] px-[84px] md:py-[63px] md:px-[89px] max-w-[519px] rounded-[40px] border border-blue-default relative mx-auto min-h-[395px];
	}
	.avatar-button {
		@apply w-[84px] h-[71px] sm:w-[99px] sm:h-[88px] rounded-[20px] border border-blue-default bg-white absolute flex items-center justify-center p-2;
	}
	.avatar-item:nth-child(2) .avatar-button,
	.avatar-item:nth-child(4) .avatar-button {
		@apply right-4 md:right-7;
	}
	.avatar-item:nth-child(1) .avatar-button,
	.avatar-item:nth-child(3) .avatar-button {
		@apply left-4 md:left-7;
	}
	.avatar-item:nth-child(1) .avatar-button,
	.avatar-item:nth-child(2) .avatar-button {
		@apply top-6 md:top-7;
	}
	.avatar-item:nth-child(3) .avatar-button,
	.avatar-item:nth-child(4) .avatar-button {
		@apply bottom-6 md:bottom-7;
	}
	.avatar-modal {
		@apply absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 backdrop-blur-[6.4px] rounded-[40px] z-10;
	}
	.avatar-select {
		@apply w-[84px] h-[71px] sm:w-[99px] sm:h-[88px] rounded-[20px] border border-blue-default bg-white flex items-center justify-center p-2;
	}
	.avatar-button img,
	.avatar-select img {
		@apply max-w-full max-h-full rounded-[inherit];
	}
	.main-navbar {
		@apply pt-[15px] pb-[15px] px-2 md:py-8 md:px-4 xl:px-8 shadow-shadow rounded-t-[55px] rounded-b-[15px] md:rounded-t-[55px] lg:rounded-[20px] border border-blue-default bg-white lg:fixed lg:left-[126px] lg:top-5 lg:w-[calc(100%-145px)] lg:before:absolute before:bottom-[calc(100%+1px)] before:h-5 before:bg-bodyBg before:w-full before:left-0 z-50;
	}
    .navbar-wrapper-for-mobile {
		@apply max-lg:fixed max-lg:w-full max-lg:left-0 max-lg:top-0 max-lg:bg-white max-lg:z-50 max-lg:pt-4 max-lg:px-4;
	}
    .btn-grow {
        @apply transform transition-transform duration-300 hover:scale-105
    }
}

@media screen and (min-width: 576px) {
	.drop--shadow {
		filter: drop-shadow(0px 24px 30px rgba(0, 0, 0, 0.2));
		-webkit-filter: drop-shadow(0px 24px 30px rgba(0, 0, 0, 0.2));
	}
}

.loader-rotation {
	animation: rotate 1.5s linear infinite;
	-webkit-animation: rotate 1.5s linear infinite;
	-moz-animation: rotate 1.5s linear infinite;
}
.loader-rotation-2 {
	animation: rotate2 0.8s linear infinite;
	-webkit-animation: rotate2 0.8s linear infinite;
	-moz-animation: rotate2 0.8s linear infinite;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}
@keyframes rotate2 {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
* {
	scrollbar-width: none;
	-ms-overflow-style: none;
    color: #132b54;
}
*::-webkit-scrollbar {
	display: none;
}

.range-slider {
	-webkit-appearance: none;
	height: 11px;
	background: #3975e3;
	outline: none;
	-webkit-transition: 0.2s;
	transition: opacity 0.2s;
	border-radius: 10px;
}
.range-slider:hover {
	opacity: 1;
}